import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useAuth } from "../Contexts/AuthContext";

function Forgot() {
  const { emailLogin } = useAuth();
  const [error, setError] = useState("");

  const onFinish = async (formInfo) => {
    try {
      await emailLogin(formInfo.userEmail);
      setError("Check your email for the link.");
    } catch (err) {
      setError(err.code.replace("auth/", ""));
    }
  };

  return (
    <div>
      <div className="backgroundSecond"></div>
      <div className="formContainer">
        <Form
          onFinish={onFinish}
          style={{ height: "30vh", top: "35%" }}
        >
          <h1 className="signLog">Get Email Link</h1>
          <p className="signError">{error}</p>
          <Form.Item
            label="Email"
            name="userEmail"
          >
            <Input
              required
              type="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Forgot;
