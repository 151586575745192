import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useAuth } from "../Contexts/AuthContext";

function Sign() {
  const { signUp, nameUser } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onFinish = async (formInfo) => {
    try {
      await signUp(formInfo.userEmail, formInfo.userPassword);

      await nameUser(formInfo.firstName, formInfo.lastName);

      navigate("/account");
    } catch (err) {
      setError(err.code.replace("auth/", ""));
    }
  };

  return (
    <div>
      <div className="backgroundSecond"></div>
      <div className="formContainer">
        <Form
          onFinish={onFinish}
          autoComplete="off"
        >
          <h1 className="signLog">
            Sign Up/<NavLink to="/login">Login</NavLink>
          </h1>
          <p className="signError">{error}</p>
          <Form.Item
            label="First Name"
            name="firstName"
          >
            <Input
              required
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="userEmail"
          >
            <Input
              required
              type="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="userPassword"
            rules={[
              { min: 9, message: "Password must be at least 9 characters." },
              {
                pattern:
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})(?!.*[' '])",
                message:
                  "Password atleast one lowercase letter, uppercase letter, number, special character, and no spaces.",
              },
            ]}
          >
            <Input.Password
              required
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["userPassword"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("userPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match.");
                },
              }),
            ]}
          >
            <Input.Password
              required
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Sign Up!
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Sign;
