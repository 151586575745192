import React from "react";

function About() {
  return (
    <div>
      <div className="backgroundSecond"> </div>
      <div className="mainTitle">
        <h1 className="bigTitle">Page coming soon!</h1>
        <p className="smallerText">Winter is here!</p>
      </div>
    </div>
  );
}

export default About;
