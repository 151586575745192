import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useAuth } from "../Contexts/AuthContext";

function Account() {
  const { user, logOut, updateAccount } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (err) {
      setError(err.code.replace("auth/", ""));
    }
  };
  const onFinish = async (formInfo) => {
    updateAccount(formInfo).then((results) => {
      setError(results);
    });
  };

  const handleChange = () => {
    setError("");
  };

  return (
    <div>
      <div className="backgroundSecond"></div>
      <div className="formContainer">
        <Form
          onFinish={onFinish}
          onChange={handleChange}
        >
          <h1 className="signLog">{user.displayName}</h1>
          <p className="signError">{error}</p>
          <Form.Item
            label="New Email"
            name="newEmail"
          >
            <Input
              type="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { min: 9, message: "Password must be at least 9 characters." },
              {
                pattern:
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})(?!.*[' '])",
                message:
                  "Password atleast one lowercase letter, uppercase letter, number, special character, and no spaces.",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match.");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Reset
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleLogOut}
            >
              Log Out
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Account;
