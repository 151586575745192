import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  
  return (
    <nav className="footer">
      Copyright &copy; Henchmen Skis Inc. 2022
      <ul>
        <li>
          <NavLink to="/about">
            -About-
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact">
            -Contact-
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Footer;