import React from "react";
import { About } from "./components";
import { Contact } from "./components";
import { Home } from "./components";
import { Skis } from "./components";
import { Route, Routes } from "react-router-dom";
import { Navigation } from "./components";
import { Footer } from "./components";
import { Log } from "./components";
import { Sign } from "./components";
import { Forgot } from "./components";
import { AuthProvider } from "./Contexts/AuthContext";
import { Account } from "./components";
import { Protected } from "./components";
import { Passwords } from "./components";
import { Dino } from "./components";
import { NotFound } from "./components";
import { Cart } from "./components";
import { Extras } from "./components";

function App() {
  return (
    <div>
      <AuthProvider>
        <Navigation />
        <Routes>
          <Route
            exact
            path="/dino-hat"
            element={<Dino />}
          />
          <Route
            exact
            path="/"
            element={<Home />}
          />
          <Route
            exact
            path="/about"
            element={<About />}
          />
          <Route
            exact
            path="/contact"
            element={<Contact />}
          />
          <Route
            exact
            path="/skis"
            element={<Skis />}
          />
          <Route
            exact
            path="/account"
            element={<Protected name="Account">{<Account />}</Protected>}
          />
          <Route
            exact
            path="/login"
            element={<Log />}
          />
          <Route
            exact
            path="/sign-up"
            element={<Sign />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<Forgot />}
          />
          <Route
            path="/reset-password"
            element={<Passwords />}
          />
          <Route
            exact
            path="/cart"
            element={<Protected name="Cart">{<Cart />}</Protected>}
          />
          <Route
            exact
            path="/extras"
            element={<Extras />}
          />
          <Route
            path="/*"
            element={<NotFound />}
          />
        </Routes>
        <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;
