import React from "react";

function Contact() {
  
  return (
    <div>
    <div className="backgroundSecond" > </div>
    <div className="mainTitle">
      <h1 className="bigTitle" >
        Page coming soon!
      </h1>
    </div>
    </div>
  );
}

export default Contact;