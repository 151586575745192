import React from "react";

function Dino() {
 
  return (
    <div>
      <p>Open image in new tab and replace offline-resources-1x src with dino-hat url.</p>
      <img src="./dinoWithHat.png" alt="Dino Hat" 
    />
    <p>
      Enter following lines into console Must add curly braces on all coordinates <br />
      Runner.instance_.tRex.config.HEIGHT = 53 <br/>
      Runner.instance_.tRex.config.HEIGHT_DUCK = 31 <br/>
      Runner.instance_.horizon.horizonLines[0].spritePos = x: 2, y: 58 <br/>
      Runner.instance_.spriteDef.CACTUS_LARGE = x: 332, y: 8 <br/>
      Runner.instance_.spriteDef.CACTUS_SMALL = x: 228, y: 8 <br/>
      Runner.instance_.distanceMeter.spritePos = x: 655, y: 8 <br/>
      Runner.instance_.spriteDef.CLOUD = x: 86, y: 8 <br/>
      Runner.instance_.spriteDef.STAR = x: 645, y: 8 <br/>
      Runner.instance_.spriteDef.RESTART= x: 2, y: 74 <br/>
      Runner.instance_.spriteDef.TEXT_SPRITE= x: 655, y: 8 <br/>
      Runner.instance_.spriteDef.PTERODACTYL = x: 134, y: 8 <br/>
      
    </p>
    
    </div>
  );
}

export default Dino;