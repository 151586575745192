import React, { useState }from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useAuth } from "../Contexts/AuthContext";




function Passwords() {
    const { passwordReset } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const location = useLocation();
    
   const onFinish= async (formInfo)=>{
        const codeSearch = new URLSearchParams(location.search);
        const code = codeSearch.get("oobCode");
  
        try {
            await passwordReset(code, formInfo.newPassword)
            navigate ("/login");
        } catch (err) {
            setError(err.code.replace("auth/",""));
        }
    
    
}
    const handleChange = ()=>{
        setError('');
    }


  return (
    <div>
      <div className="backgroundSecond"></div>
      <div >
      <Form onFinish={onFinish} onChange={handleChange}>
        <h1 className="signLog">Reset Password</h1>
        <p className="signError">{error}</p>
        <Form.Item 
         label='New Password'
         name='newPassword' 
         required
         rules={[
          {min: 9, message:'Password must be at least 9 characters.'},
          {pattern:("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})(?!.*[' '])"),
          message:'Password atleast one lowercase letter, uppercase letter, number, special character, and no spaces.'}
        ]}
         >
          <Input.Password required placeholder="Password"/>
        </Form.Item>
        <Form.Item  
        label='Confirm New Password' 
        name='confirmPassword' 
        dependencies={['newPassword']}
        hasFeedback
        required
        rules={[
          ({ getFieldValue }) => ({
            validator(_,value) {
              if(getFieldValue('newPassword') === value){
                return Promise.resolve()
              }
              return Promise.reject('Passwords do not match.')
            }
          })
        ]}
        >
          <Input.Password required placeholder="Confirm Password"/>
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">Reset Password</Button>
        </Form.Item>
      </Form>
      
      </div>
    </div>
  );
}

export default Passwords;