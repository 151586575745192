import React from "react";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Henchmen skis is a new, fully Canadian, ski manufacturer that is devoted to creating the most interesting skis on the market."
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href="http://henchmenskis.ca"
        />

        <title>Henchmen Skis</title>
      </Helmet>
      <div className="backgroundSecond"> </div>
      <div className="mainTitle">
        <h1 className="bigTitle">Henchmen Skis</h1>
        <p className="smallerText">Coming soon!</p>
      </div>
    </div>
  );
}

export default Home;
