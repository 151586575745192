import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import { ShoppingTwoTone } from "@ant-design/icons";
import { Badge } from "antd";

function Navigation() {
  const { user, cartNum } = useAuth();
  const [acclog, setAcclog] = useState("/login");
  const [acclogTitle, setAcclogTitle] = useState("Login");

  useEffect(() => {
    if (user) {
      setAcclog("/account");
      setAcclogTitle("Account");
    } else {
      setAcclog("/login");
      setAcclogTitle("Login");
    }
  }, [user]);

  return (
    <nav className="nav">
      <NavLink
        className="site-Title"
        to="/"
      >
        Henchmen Skis
      </NavLink>
      <ul>
        <li>
          <NavLink to="/skis">Skis</NavLink>
        </li>
        <li>
          <NavLink to="/extras">Extras</NavLink>
        </li>
        <li>
          <NavLink to={acclog}>{acclogTitle}</NavLink>
        </li>
        <li>
          <NavLink to="/cart">
            <Badge
              count={cartNum}
              showZero
              size="small"
              offset={[5, -3]}
            >
              <ShoppingTwoTone twoToneColor="#000000" />
            </Badge>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
