import React, { useState } from "react";
import Select from "react-select";

import { GlassMagnifier } from "react-image-magnifiers";
import { Helmet } from "react-helmet";
import { useAuth } from "../Contexts/AuthContext";

function Strange() {
  const { products, forceLoad } = useAuth();
  const scheme = [
    { value: "./redtopsheet792022.png", label: "Red" },
    { value: "./tealTopsheet.png", label: "Teal" },
  ];
  const [colorSelected, setColorSelected] = useState(scheme[0]["value"]);
  let instock = "Out of stock";
  let styler = "ofs";
  const colorChange = (newColor) => {
    forceLoad();
    setColorSelected(newColor);
  };
  if (products) {
    if (
      colorSelected === "./redtopsheet792022.png" &&
      products.strangeRed.Stock !== 0
    ) {
      instock = `In stock: ${products.strangeRed.Stock} left`;
      styler = "yayin";
    }
    if (
      colorSelected === "./tealTopsheet.png" &&
      products.strangeTeal.Stock !== 0
    ) {
      instock = `In stock: ${products.strangeTeal.Stock} left`;
      styler = "yayin";
    }
  }

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Get the first look at the Strange Profession, our first skis on the market, coming soon!"
        />
        <link
          rel="canonical"
          href="http://henchmenskis.ca/skis"
        />

        <title>Henchmen Skis</title>
      </Helmet>
      <div className="backgroundThird"> </div>
      <div className="picBox">
        <h1>Strange Profession</h1>
        Color:
        <Select
          className="selectBox"
          defaultValue={scheme[0]}
          options={scheme}
          onChange={(selectedObject) => colorChange(selectedObject.value)}
          isSearchable={false}
        />
        Length: 186cm
        <br />
        Radius: 19m
        <br />
        Profile: 132-102-127
        <br />
        Weight: 4kg per pair
        <br />
        <div className={styler}>{instock}</div>
      </div>

      <GlassMagnifier
        className="skiPic"
        imageSrc={colorSelected}
        imageAlt="Henchmen Skis"
        allowOverflow
        magnifierSize={250}
        magnifierOffsetX={100}
      />
    </div>
  );
}

export default Strange;
