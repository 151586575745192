import React, { useEffect, useState } from "react";
import { InputNumber, Button, Form, Input } from "antd";
import { useAuth } from "../Contexts/AuthContext";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { functions } from "../Firebase";
import { httpsCallable } from "firebase/functions";

//import { functions } from "../Firebase";
//import { getFunctions, httpsCallable } from "firebase/functions";
const stripePromise = loadStripe(
  "pk_live_51LyfipDGRqtLS0Bg9gGgF5OKmPjLOc7sv7bEVkn1zqPoHLUB2KENQPpXDpXjI3YrwtJKzUuS141BmBEM5UjDV18x00mC3Uq35p"
);
function Cart() {
  const { updateCart, removeFromCart, products, cart, user } = useAuth();
  const [clientSecret, setClientSecret] = useState("");
  const [address, setAddress] = useState(null);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const handleClick = (key) => {
    if (document.getElementById(key).valueAsNumber) {
      updateCart(key, document.getElementById(key).valueAsNumber);
    } else {
      removeFromCart(key);
    }
  };

  const totalPrice = () => {
    let price = 0;
    Object.keys(cart).map((key) => {
      return (price += cart[key] * products[key].price);
    });
    return price;
  };

  const itemGene = () => {
    if (cart && Object.keys(cart).length === 0) {
      return <p>No items in cart.</p>;
    } else if (products && cart) {
      return (
        <ul className="extras">
          {Object.keys(cart).map((key) => {
            return (
              <li
                key={key}
                className="itemCart"
              >
                <div className="infoCart">
                  <p className="NoMargin">{products[key].title}</p>
                  <div className="inline">
                    <img
                      className="cartImg"
                      src={products[key].src}
                      alt={products[key].title}
                      style={products[key].size}
                    />
                    <div className="fullWidth">
                      <p className="NoMargin">price</p>
                      <p className="NoMargin">
                        ${products[key].price * cart[key]}
                      </p>
                    </div>

                    <div className="inline">
                      Qt:
                      <InputNumber
                        type="number"
                        defaultValue={cart[key]}
                        min={0}
                        id={key}
                        max={products[key].Stock}
                        parser={(value) => value.replace(".", "")}
                      />
                    </div>
                    <Button
                      onClick={() => handleClick([key])}
                      style={{ width: "fit-content" }}
                    >
                      Update
                    </Button>
                    <CloseCircleOutlined onClick={() => removeFromCart(key)} />
                  </div>
                </div>
              </li>
            );
          })}
          <li className="totalPrice">Sub Total: ${totalPrice()}</li>
        </ul>
      );
    } else {
      return <p>Loading...</p>;
    }
  };
  const handleFinish = (formInfo) => {
    setAddress({
      address: {
        line1: formInfo.address,
        line2: formInfo.unit,
        city: formInfo.city,
        state: formInfo.province,
        country: formInfo.country,
        postal_code: formInfo.code,
      },
      name: user.displayName,
    });
  };
  const shipping = () => {
    if (address) {
      return (
        clientSecret && (
          <Elements
            options={options}
            stripe={stripePromise}
          >
            <CheckoutForm />
          </Elements>
        )
      );
    } else {
      return (
        <Form onFinish={handleFinish}>
          <Form.Item
            label="Address"
            name="address"
          >
            <Input
              required
              type="text"
              placeholder="Address"
            />
          </Form.Item>
          <Form.Item
            label="Unit"
            name="unit"
          >
            <Input
              type="text"
              placeholder="Unit"
            />
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
          >
            <Input
              required
              type="text"
              placeholder="City"
            />
          </Form.Item>
          <Form.Item
            label="Province"
            name="province"
          >
            <Input
              required
              type="text"
              placeholder="Province"
            />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
          >
            <Input
              required
              type="text"
              placeholder="Country"
            />
          </Form.Item>
          <Form.Item
            label="Postal Code"
            name="code"
          >
            <Input
              required
              type="text"
              placeholder="Postal Code"
            />
          </Form.Item>
          <Button htmlType="submit">Confirm Shipping Address</Button>
        </Form>
      );
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (cart && products && address) {
      const data = { cart, products, address, email: user.email };
      const pay = httpsCallable(functions, "pay");
      pay(data).then((res) => {
        setClientSecret(res.data.clientSecret);
      });
    }
  }, [cart, products, address, user.email]);

  return (
    <div>
      <div
        className="backgroundSecond"
        style={{ height: "150vh" }}
      >
        {" "}
      </div>
      <div className="cartStripe">
        <div className="Cart">{itemGene()}</div>
        <div className="stripeBox">{shipping()}</div>
      </div>
    </div>
  );
}

export default Cart;
