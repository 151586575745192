import React from "react";
import { InputNumber, Button } from "antd";
import { useAuth } from "../Contexts/AuthContext";
import { NavLink } from "react-router-dom";

function Extras() {
  const { user, addToCart } = useAuth();

  const handleClick = (item) => {
    addToCart(item, document.getElementById(item).valueAsNumber);
  };
  const block = (id) => {
    if (!user) {
      return (
        <Button type="primary">
          <NavLink to="/sign-up">Please Sign up/ Login</NavLink>
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          onClick={() => handleClick(id)}
        >
          Add to cart
        </Button>
      );
    }
  };

  return (
    <div>
      <div className="backgroundThird"> </div>
      <ul className="extras">
        <li className="item">
          <img
            src="./largeSticker.png"
            alt="Large Sticker"
            style={{ width: "25vw", height: "12.5vw", padding: "1.5vmin" }}
          />
          <div className="info">
            <h1 className="NoMargin">Large Sticker</h1>
            <p>125mm by 55mm vinyl sticker. UV safe for outdoor use.</p>
            <div className="interactions">
              <p>$3/each</p>
              <InputNumber
                type="number"
                defaultValue={0}
                min={0}
                id="stickerLarge"
                max={100}
                parser={(value) => value.replace(".", "")}
              />
              {block("stickerLarge")}
            </div>
          </div>
        </li>
        <li className="item">
          <img
            src="./smallSticker.png"
            alt="Small Sticker"
            style={{ width: "12.5vw", height: "12.5vw", padding: "1.5vmin" }}
          />
          <div className="info">
            <h1 className="NoMargin">Small Sticker</h1>
            <p>50mm diameter round vinyl sticker. UV safe for outdoor use.</p>
            <div className="interactions">
              <p>$1.50/each</p>
              <InputNumber
                type="number"
                defaultValue={0}
                min={0}
                id="stickerSmall"
                max={100}
                parser={(value) => value.replace(".", "")}
              />
              {block("stickerSmall")}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Extras;
