import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useAuth } from "../Contexts/AuthContext";
import { Helmet } from "react-helmet";

function Log() {
  const { login } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onFinish = async (formInfo) => {
    try {
      await login(formInfo.userEmail, formInfo.userPassword);
      return navigate("/account");
    } catch (err) {
      setError(err.code.replace("auth/", ""));
    }
  };
  const handleChange = () => {
    setError("");
  };

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Login or sign up."
        />
        <link
          rel="canonical"
          href="http://henchmenskis.ca/login"
        />

        <title>Henchmen Skis</title>
      </Helmet>
      <div className="backgroundSecond"></div>
      <div className="formContainer">
        <Form
          onChange={handleChange}
          onFinish={onFinish}
          style={{ height: "60vh", top: "20%" }}
        >
          <h1 className="signLog">
            Login/<NavLink to="/sign-up">Sign Up</NavLink>
          </h1>
          <p className="signError">{error}</p>
          <Form.Item
            label="Email"
            name="userEmail"
          >
            <Input
              required
              type="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="userPassword"
          >
            <Input.Password
              required
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>
          <NavLink to="/forgot-password">Forgot Password?</NavLink>
        </Form>
      </div>
    </div>
  );
}

export default Log;
