import React from "react";

function NotFound() {
 
  return (
    <div>
      <div className="backgroundThird" > </div>
      <div className="mainTitle">
        <h1 className="bigTitle" >
          Page not found.
        </h1>
      </div>
    </div>
  );
}

export default NotFound;