import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";

const Protected = ({ children, name }) => {
  const { user } = useAuth();

  if (user !== 0) {
    if (!user && name === "Account") {
      return <Navigate to="/login" />;
    } else if (!user && name === "Cart") {
      return <Navigate to="/sign-up" />;
    } else return children;
  }
};

export default Protected;
