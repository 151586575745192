import React, { createContext, useContext, useEffect, useState } from "react";
import db, { auth } from "../Firebase";
import {
  setDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  increment,
  deleteField,
  collection,
} from "firebase/firestore";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setCurrentUser] = useState(0);
  const [cartNum, setCartNum] = useState(0);
  const [load, setLoad] = useState(false);
  const [products, setProducts] = useState(null);
  const [cart, setCart] = useState(null);

  async function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then(() => {
      setDoc(doc(db, auth.currentUser.uid, "cart"), {});
    });
  }

  function nameUser(firstName, lastName) {
    return updateProfile(auth.currentUser, {
      displayName: `${firstName} ${lastName}`,
    });
  }
  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function emailLogin(email) {
    return sendPasswordResetEmail(auth, email);
  }
  function passwordReset(code, password) {
    return confirmPasswordReset(auth, code, password);
  }
  function addToCart(item, num) {
    if (num) {
      updateDoc(doc(db, user.uid, "cart"), {
        [item]: increment(num),
      }).then(() => {
        forceLoad();
      });
    }
  }
  function updateCart(item, num) {
    if (num) {
      updateDoc(doc(db, user.uid, "cart"), {
        [item]: num,
      }).then(() => {
        forceLoad();
      });
    }
  }
  function removeFromCart(item) {
    updateDoc(doc(db, user.uid, "cart"), {
      [item]: deleteField(),
    }).then(() => {
      forceLoad();
    });
  }

  function forceLoad() {
    setLoad(load !== true);
  }
  async function updateAccount(formInfo) {
    if (user.email !== formInfo.newEmail && formInfo.newEmail) {
      try {
        await updateEmail(user, formInfo.newEmail);
        return "Update Successful";
      } catch (err) {
        return err.code.replace("auth/", "");
      }
    }
    if (user.password !== formInfo.newPassword && formInfo.newPassword) {
      try {
        await updatePassword(user, formInfo.newPassword);
        return "Update Successful";
      } catch (err) {
        return err.code.replace("auth/", "");
      }
    }
  }

  useEffect(() => {
    async function getCart() {
      if (user && user.uid) {
        const data = await getDoc(doc(db, user.uid, "cart"));
        const cart = data.data();
        setCart(cart);
        const num = Object.values(cart).reduce((a, b) => a + b, 0);
        setCartNum(num);
      } else {
        setCartNum(0);
      }
    }
    getCart();
  }, [load, user]);

  useEffect(() => {
    if (user) {
      const obj = {};
      async function getProducts() {
        const data = await getDocs(collection(db, "products"));
        data.docs.forEach((doc) => {
          const id = doc.id;
          obj[id] = doc.data();
        });
        setProducts(obj);
      }
      getProducts();
    }
  }, [load, user]);

  useEffect(() => {
    onAuthStateChanged(auth, (CurrentUser) => {
      setCurrentUser(CurrentUser);
      setPersistence(auth, browserSessionPersistence);
    });
  }, []);

  const value = {
    user,
    signUp,
    login,
    logOut,
    emailLogin,
    passwordReset,
    nameUser,
    cartNum,
    addToCart,
    removeFromCart,
    products,
    forceLoad,
    updateCart,
    updateAccount,
    cart,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
